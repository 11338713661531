import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Slider from "react-slick"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import ArrowLeft from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/ArrowRight"
import ArrowRight from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements/ArrowLeft"

import {
  ServicesTreatmentsPagesHero,
  Seo,
  GenericPagesHero,
} from "../components/elements"

const datoCmsCancerGenericPage = ({ data, pageContext }) => {
  const page = data.datoCmsCancerGenericPage
  const heroimg = data.datoCmsAsset.gatsbyImageData
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowRight />,
    nextArrow: <ArrowLeft />,
  }

  return (
    <Layout>
      <Seo title={page.title} />

      <GenericPagesHero page={page} heroimg={heroimg}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </GenericPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", "full", 3 / 4]}>
                <Box
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />
              </Box>

              {page.images.length > 0 ? (
                <Box width="full" my={6}>
                  <Slider {...settings}>
                    {page.images.map(({ gatsbyImageData }) => (
                      <GatsbyImage image={gatsbyImageData} alt={page.title} />
                    ))}
                  </Slider>
                </Box>
              ) : null}
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default datoCmsCancerGenericPage

export const query = graphql`
  query genericPageQuery($slug: String!) {
    datoCmsCancerGenericPage(slug: { eq: $slug }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      images {
        gatsbyImageData
      }
      heroImage {
        gatsbyImageData
      }
      slug
      title
      meta {
        status
      }
    }
    datoCmsAsset(id: { eq: "DatoCmsAsset-12410470" }) {
      id
      gatsbyImageData
    }
  }
`
